import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import 'feather-icons'
import styled from 'styled-components'
import Panel from '../components/Panel'
import { PageWrapper, ContentWrapperLarge, StyledIcon } from '../components/index'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import Column, { AutoColumn } from '../components/Column'
import { ButtonLight, ButtonDark } from '../components/ButtonStyled'
import PairChart from '../components/PairChart'
import Link from '../components/Link'
import TxnList from '../components/TxnList'
import ListHolder from '../components/ListHolder'
import Loader from '../components/LocalLoader'
import { BasicLink } from '../components/Link'
import Search from '../components/Search'
import { formattedNum, formattedPercent, getPoolLinkById, getSwapLink, formatPercent } from '../utils'
import { useColor } from '../hooks'
import { usePairData, usePairTransactions, usePairHolders } from '../contexts/PairData'
import { TYPE, ThemedBackground } from '../Theme'
import { transparentize } from 'polished'
import CopyHelper from '../components/Copy'
import { useMedia } from 'react-use'
import TokenLogo from '../components/TokenLogo'
import { Hover } from '../components'
// import { useEthPrice } from '../contexts/GlobalData'
import Warning from '../components/Warning'
import { usePathDismissed, useSavedPairs } from '../contexts/LocalStorage'

import { Bookmark, PlusCircle } from 'react-feather'
import FormattedName from '../components/FormattedName'
// import { useListedTokens } from '../contexts/Application'
import MultiTokenLogo from '../components/MultiTokenLogo'
import axios from 'axios'
import { numFormat } from '../utils/index'

const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 6px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const TokenDetailsLayout = styled.div`
  display: inline-grid;
  width: 100%;
  grid-template-columns: auto auto auto auto auto 1fr;
  column-gap: 60px;
  align-items: start;

  &:last-child {
    align-items: center;
    justify-items: end;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
      margin-bottom: 1rem;
    }

    &:last-child {
      align-items: start;
      justify-items: start;
    }
  }
`

const FixedPanel = styled(Panel)`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 10px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
  }
`

const HoverSpan = styled.span`
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function PairPage({ pairAddress, history }) {
  const {
    id: poolId,
    tokens,
    name,
    reserveUSD,
    trackedReserveUSD,
    oneDayVolumeUSD,
    oneDayFee24hUSD,
    volumeChangeUSD,
    oneDayVolumeUntracked,
    volumeChangeUntracked,
    liquidityChangeUSD,
    swapFee,
    version
  } = usePairData(pairAddress)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const transactions = usePairTransactions(pairAddress)

  const holders = usePairHolders(pairAddress)

  const backgroundColor = useColor(pairAddress)

  // liquidity
  const liquidity = trackedReserveUSD
    ? formattedNum(trackedReserveUSD, true)
    : reserveUSD
    ? formattedNum(reserveUSD, true)
    : '-'
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  // mark if using untracked liquidity
  const [usingTracked, setUsingTracked] = useState(true)
  useEffect(() => {
    setUsingTracked(!trackedReserveUSD ? false : true)
  }, [trackedReserveUSD])

  // volume	  // volume
  const volume =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? formattedNum(oneDayVolumeUSD === 0 ? oneDayVolumeUntracked : oneDayVolumeUSD, true)
      : oneDayVolumeUSD === 0
      ? '$0'
      : '-'

  // mark if using untracked volume
  const [usingUtVolume, setUsingUtVolume] = useState(false)
  useEffect(() => {
    setUsingUtVolume(oneDayVolumeUSD === 0 ? true : false)
  }, [oneDayVolumeUSD])

  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked)

  // get fees	  // get fees
  const fees = formattedNum(oneDayFee24hUSD, true)

  const [lpPrice, setLpPrice] = useState(0)

  const fetchLPPrice = async (pairAddress) => {
    // FIXME: Update api
    axios.get('https://price-aggregator.vswap.fi/api/price/token-price?addresses=' + pairAddress)
    .then(result => {
      const lpPrice = (result.data && result.data.data) ? result.data.data[pairAddress] : 0
      setLpPrice(lpPrice)
    })
    .catch(err => {
      console.log('Error fetchLPPrice', err)
      return 0
    })
  }
  useEffect(() => {
    if (pairAddress) {
      fetchLPPrice(pairAddress)
    }
  }, [pairAddress])

  // token data for usd
  // const [ethPrice] = useEthPrice()
  // const token0USD =
  //   token0?.derivedETH && ethPrice ? formattedNum(parseFloat(token0.derivedETH) * parseFloat(ethPrice), true) : ''
  //
  // const token1USD =
  //   token1?.derivedETH && ethPrice ? formattedNum(parseFloat(token1.derivedETH) * parseFloat(ethPrice), true) : ''
  //
  // // rates
  // const token0Rate = reserve0 && reserve1 ? formattedNum(reserve1 / reserve0) : '-'
  // const token1Rate = reserve0 && reserve1 ? formattedNum(reserve0 / reserve1) : '-'
  //
  // // formatted symbols for overflow
  // const formattedSymbol0 = token0?.symbol.length > 6 ? token0?.symbol.slice(0, 5) + '...' : token0?.symbol
  // const formattedSymbol1 = token1?.symbol.length > 6 ? token1?.symbol.slice(0, 5) + '...' : token1?.symbol

  const below1080 = useMedia('(max-width: 1080px)')
  const below900 = useMedia('(max-width: 900px)')
  const below600 = useMedia('(max-width: 600px)')

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  const [savedPairs, addPair] = useSavedPairs()

  // const listedTokens = useListedTokens() || []
  // FIXME: 
  const showWarning = false //tokens && tokens.filter(token => listedTokens.includes(token.address)).length === 0
  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />
      <span />
      <Warning
        type={'pair'}
        show={!dismissed && showWarning}
        setShow={markAsDismissed}
        address={pairAddress}
      />
      <ContentWrapperLarge>
        <RowBetween>
          <TYPE.body>
            <BasicLink to="/pools">{'Pools '}</BasicLink>→  {name}
          </TYPE.body>
          {!below600 && <Search small={true} />}
        </RowBetween>
        {/* FIXME:  */}
        <WarningGrouping disabled={false}>
        {/* <WarningGrouping disabled={!dismissed && showWarning}> */}
          <DashboardWrapper>
            <AutoColumn gap="40px" style={{ marginBottom: '1.5rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }}>
                <RowFixed style={{ flexWrap: 'wrap', minWidth: '100px' }}>
                  <RowFixed>
                    <MultiTokenLogo tokens={tokens}/>
                    <TYPE.main fontSize={below1080 ? '1rem' : '1.5rem'} style={{ margin: '0 1rem' }}>
                      {tokens? (
                        <>
                          {tokens.map(token => {
                            return (
                              <React.Fragment key={token?.address}>
                                {token?.poolPercentage ? <span>{token?.poolPercentage}% </span> : null}
                                <HoverSpan
                                  onClick={() => history.push(`/token/${token?.address}`)}>{token.symbol} </HoverSpan>
                              </React.Fragment>
                            )
                          }).reduce((accu, elem) => {
                            return accu === null ? [elem] : [...accu, (<span key={elem}> - </span>), elem]
                          }, null)}
                          {' '} Pool
                        </>
                      ) : (
                        ''
                      )}
                    </TYPE.main>
                  </RowFixed>
                </RowFixed>
                <RowFixed
                  ml={below900 ? '0' : '2.5rem'}
                  mt={below1080 && '1rem'}
                  style={{ flexDirection: below1080 ? 'row-reverse' : 'initial' }}
                >
                  {!!!savedPairs[pairAddress] && !below1080 ? (
                    <Hover onClick={() => addPair(pairAddress, tokens)}>
                      <StyledIcon>
                        <PlusCircle style={{ marginRight: '0.5rem' }} />
                      </StyledIcon>
                    </Hover>
                  ) : !below1080 ? (
                    <StyledIcon>
                      <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                    </StyledIcon>
                  ) : (
                    <></>
                  )}
                  <Link external href={getPoolLinkById(poolId, false, version)}>
                    <ButtonLight color={backgroundColor}>+ Add Liquidity</ButtonLight>
                  </Link>

                  {/* <Link external href={getPoolLinkById(poolId, true)}>
                    <ButtonDark ml={!below1080 && '.5rem'} mr={below1080 && '.5rem'} color={backgroundColor}>- Remove Liquidity</ButtonDark>
                  </Link> */}
                  {
                    tokens && 
                    <Link external href={tokens ? getSwapLink(tokens[0].address, tokens[1].address) : ''}>
                      <ButtonDark ml={!below1080 && '.5rem'} mr={below1080 && '.5rem'} color={backgroundColor}>
                        Trade
                      </ButtonDark>
                    </Link>
                  }
                </RowFixed>
              </div>
            </AutoColumn>
            <AutoRow
              gap="6px"
              style={{
                width: 'fit-content',
                marginTop: below900 ? '1rem' : '0',
                marginBottom: below900 ? '0' : '2rem',
                flexWrap: 'wrap'
              }}
            >
              {tokens && tokens.map((token) => (
              <FixedPanel key={token.id} onClick={() => history.push(`/token/${token.address}`)}>
                <RowFixed>
                  <TokenLogo address={token.address} size={'16px'} />
                  <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                    {token.priceUSD ? `1 ${token.symbol} = ${formattedNum(token.priceUSD,true)}` : '-'}
                  </TYPE.main>
                </RowFixed>
              </FixedPanel>
              ))}
              {/*<FixedPanel onClick={() => history.push(`/token/${token1?.id}`)}>*/}
              {/*  <RowFixed>*/}
              {/*    <TokenLogo address={token1?.id} size={'16px'} />*/}
              {/*    <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>*/}
              {/*      {token0 && token1*/}
              {/*        ? `1 ${formattedSymbol1} = ${token1Rate} ${formattedSymbol0}  ${*/}
              {/*            parseFloat(token1?.derivedETH) ? '(' + token1USD + ')' : ''*/}
              {/*          }`*/}
              {/*        : '-'}*/}
              {/*    </TYPE.main>*/}
              {/*  </RowFixed>*/}
              {/*</FixedPanel>*/}
            </AutoRow>
            <>
              {!below1080 && <TYPE.main fontSize={'1.125rem'}>Pair Stats</TYPE.main>}
              <PanelWrapper style={{ marginTop: '1.5rem' }}>
                <Panel style={{ height: '100%' }}>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Total Liquidity {!usingTracked ? '(Untracked)' : ''}</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {liquidity}
                      </TYPE.main>
                      <TYPE.main>{liquidityChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel style={{ height: '100%' }}>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Volume (24hrs) {usingUtVolume && '(Untracked)'}</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {volume}
                      </TYPE.main>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel style={{ height: '100%' }}>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <StyledHeader>
                        <TYPE.main>Fees (24hrs) </TYPE.main>
                        <TYPE.main>Swap fee: {formatPercent(swapFee)}</TYPE.main>
                      </StyledHeader>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {fees}
                      </TYPE.main>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>

                <Panel style={{ height: '100%' }}>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Pooled Tokens</TYPE.main>
                      <div />
                    </RowBetween>
                    {tokens && tokens.map(token => {
                      return (
                    <Hover key={token.id} onClick={() => history.push(`/token/${token?.address}`)} fade={true}>
                      <AutoRow gap="4px">
                        <TokenLogo address={token?.address} />
                        <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                          <RowFixed>
                            {token.balance ? formattedNum(token.balance) : ''}{' '}
                            <FormattedName text={token?.symbol ?? ''} maxCharacters={8} margin={true} />
                          </RowFixed>
                        </TYPE.main>
                      </AutoRow>
                    </Hover>
                        )})}

                  </AutoColumn>
                </Panel>
                <Panel style={{ gridColumn: below1080 ? '1' : '2/4', gridRow: below1080 ? '' : '1/5' }}>
                  <PairChart
                    address={pairAddress}
                    color={backgroundColor}
                    base={100}
                    swapFee={swapFee}
                  />
                </Panel>
              </PanelWrapper>
              
              <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
                Transactions
              </TYPE.main>{' '}

              <Panel
                style={{
                  marginTop: '1.5rem'
                }}
              >
                {transactions ? <TxnList transactions={transactions} /> : <Loader />}
              </Panel>

              <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
                Holders
              </TYPE.main>{' '}
              <Panel
                style={{
                  marginTop: '1.5rem'
                }}
              >
                {holders ? <ListHolder holders={holders} /> : <Loader />}
              </Panel>

              <RowBetween style={{ marginTop: '3rem' }}>
                <TYPE.main fontSize={'1.125rem'}>Pair Information</TYPE.main>{' '}
              </RowBetween>
              <Panel
                rounded
                style={{
                  marginTop: '1.5rem'
                }}
                p={20}
              >
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.main>Pair Name</TYPE.main>
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      <RowFixed>
                        ${name}
                      </RowFixed>
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.main>Pair Address</TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }}>
                        {pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={pairAddress} />
                    </AutoRow>
                  </Column>
                  {tokens && tokens.map((token) => (
                  <Column key={token.id}>
                    <TYPE.main>
                      <RowFixed>
                        <FormattedName text={token.symbol ?? ''} maxCharacters={8} />{' '}
                        <span style={{ marginLeft: '4px' }}>Address</span>
                      </RowFixed>
                    </TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }}>
                        {token.address.slice(0, 6) + '...' + token.address.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={token.address} />
                    </AutoRow>
                  </Column>
                  ))}
                  <Column>
                    <TYPE.main>
                      <RowFixed>
                        <span style={{ marginLeft: '4px' }}>LP Price</span>
                      </RowFixed>
                    </TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }}>
                        ~${numFormat(lpPrice)}
                      </TYPE.main>
                    </AutoRow>
                  </Column>
                  {/*<Column>*/}
                  {/*  <TYPE.main>*/}
                  {/*    <RowFixed>*/}
                  {/*      <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />{' '}*/}
                  {/*      <span style={{ marginLeft: '4px' }}>Address</span>*/}
                  {/*    </RowFixed>*/}
                  {/*  </TYPE.main>*/}
                  {/*  <AutoRow align="flex-end">*/}
                  {/*    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={16}>*/}
                  {/*      {token1 && token1.id.slice(0, 6) + '...' + token1.id.slice(38, 42)}*/}
                  {/*    </TYPE.main>*/}
                  {/*    <CopyHelper toCopy={token1?.id} />*/}
                  {/*  </AutoRow>*/}
                  {/*</Column>*/}
                  <ButtonLight color={backgroundColor}>
                    <Link color={backgroundColor} external href={'https://explorer-mainnet.maticvigil.com/address/' + pairAddress}>
                      View on Matic Explorer ↗
                    </Link>
                  </ButtonLight>
                </TokenDetailsLayout>
              </Panel>
            </>
          </DashboardWrapper>
        </WarningGrouping>
      </ContentWrapperLarge>
    </PageWrapper>
  )
}

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export default withRouter(PairPage)
