import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import config from '../config'

const currentChain = localStorage.getItem('currentChain') || 'POLYGON'
const url = currentChain && ['BSC', 'POLYGON'].includes(currentChain) ? config.subgraph[currentChain] : config.subgraph['POLYGON'];

export const valueLiquidClient = new ApolloClient({
  link: new HttpLink({
    uri:  url
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const testLiquidClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/hungpq14/explore-template'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswapv2'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const vLiquidClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/valueliquid/balancer-kovan'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true
})


const urlBlock = currentChain && ['BSC', 'POLYGON'].includes(currentChain) ? config.block[currentChain] : config.block['POLYGON'];

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: urlBlock,
  }),
  cache: new InMemoryCache(),
})
