export const mappingGlobalData = result => {
  if (!result.data || (result.data.uniswapFactories && result.data.uniswapFactories.length === 0)) return {}

  return result.data.uniswapFactories.reduce((a, b) => {
    return Object.assign({}, a, {
      [b.id]: {
        totalVolumeUSD: b.totalSwapVolume,
        totalVolumeETH: 0,
        untrackedVolumeUSD: 0,
        totalLiquidityUSD: b.totalLiquidity,
        totalLiquidityETH: 0,
        txCount: b.txCount,
        pairCount: b.finalizedPoolCount,
        totalSwapFee: b.totalSwapFee
      }
    })
  }, {})
}

export const mappingTransactionData = transaction => {
  const {
    id,
    tokenIn,
    tokenOut,
    tokenInSym,
    tokenOutSym,
    tokenAmountIn,
    tokenAmountOut,
    userAddress,
    value,
    timestamp
  } = transaction

  return {
    amount0In: tokenAmountIn,
    amount0Out: 0,
    amount1In: 0,
    amount1Out: tokenAmountOut,
    amountUSD: value,
    to: userAddress.id,
    transaction: {
      id: id.split('-')[0],
      timestamp: timestamp
    },
    pair: {
      token0: {
        id: tokenIn,
        symbol: tokenInSym
      },
      token1: {
        id: tokenOut,
        symbol: tokenOutSym
      }
    }
  }
}

export const generatePairName = pair => {
  return pair.tokens.map(token => token.poolPercentage ? `${token.poolPercentage}% ${token.symbol}` : token.symbol).join(' - ')
}
