import React, { useEffect, useState } from 'react'
import 'feather-icons'
import { TYPE } from '../../../Theme'
import Panel from '../../../components/Panel'
import { formatAddress, formattedNum, rawPercent} from '../../../utils'
import { Flex } from 'rebass'
import { Divider } from '../../../components'
import LocalLoader from '../../../components/LocalLoader'
import Link from '../../../components/Link'
import { DashboardWrapper, List, DataText, PageButtons, Arrow } from './Styled'
import styled from 'styled-components'

export const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-areas: 'name liq vol';
  padding: 0 1.125rem;

  > * {
    justify-content: flex-end;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 20px;
    }
  }

  @media screen and (min-width: 740px) {
    padding: 0 1.125rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1080px) {
    padding: 0 1.125rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`


const getPriceUSD = (holder) => {
  if (!holder || !holder.poolId) return 0

  const price = (holder.poolId.liquidity / holder.poolId.totalShares)
  return formattedNum(holder.stakeBalance * price, 2)
}

const addressUrl = 'https://explorer-mainnet.maticvigil.com/address/'
const ListItem = ({ holder, index }) => {
  if (holder && holder.id ) {
    return (
      <DashGrid style={{ height: '48px' }} focus={true}>
        <DataText>
          <Link href={addressUrl + '' + holder.userAddress.id }>
            {formatAddress(holder.userAddress.id)}
          </Link>
        </DataText>
        <DataText>
          {formattedNum(holder.stakeBalance)} ({getPriceUSD(holder)})
        </DataText>
        <DataText>
          {holder.stakingPoolId.totalStakedShare > 0 ? rawPercent((holder.stakeBalance / holder.stakingPoolId.totalStakedShare)) : 0}
        </DataText>
      </DashGrid>
    )
  } else { 
    return ''
  }
}

const ITEMS_PER_PAGE = 10


function Holder({holders}) {

  const [page,setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    if (holders) {
      let extraPages = 1
      if (holders.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(holders.length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [holders])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const holdersFilter =
  holders &&
  holders
    .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
    .map((holder, index) => {
      return (
        holder && (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * ITEMS_PER_PAGE + index + 1} holder={holder} />
            <Divider />
          </div>
        )
      )
    })


  return (
    <DashboardWrapper>
      <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
       Holders
      </TYPE.main>{' '}
      <Panel
        style={{
          marginTop: '1.5rem'
        }}
      >
        <DashGrid
          center={true}
          style={{ height: 'fit-content', padding: '0 1.125rem 1rem 1.125rem' }}
        >
          <Flex alignItems="center">
            <TYPE.main area="startReward">Address</TYPE.main>
          </Flex>
          <Flex alignItems="center">
            <TYPE.main area="startReward">Quantity</TYPE.main>
          </Flex>
          <Flex alignItems="center">
            <TYPE.main area="endReward">Percent</TYPE.main>
          </Flex>
        </DashGrid>
        <Divider />
        <List p={0}>{!holdersFilter ? <LocalLoader /> : holdersFilter}</List>
        {
          holders && holders.length > 0 && <PageButtons>
            <div
              onClick={e => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={e => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        }
        
      </Panel>
    </DashboardWrapper>
  )
}

export default Holder
