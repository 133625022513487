import React, { useEffect, useState } from 'react'
import 'feather-icons'
import { TYPE } from '../../../Theme'
import Panel from '../../../components/Panel'
import { formatAddress } from '../../../utils'
import { Flex } from 'rebass'
import { Divider } from '../../../components'
import LocalLoader from '../../../components/LocalLoader'
import Link from '../../../components/Link'
import { DashboardWrapper, List, DataText, PageButtons, Arrow } from './Styled'
import styled from 'styled-components'
import { useMedia } from 'react-use'

const blockUrl = 'https://explorer-mainnet.maticvigil.com/block/'
const tokenUrl = 'https://explorer-mainnet.maticvigil.com/token/'

export const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-areas: 'name liq vol';
  padding: 0 1.125rem;

  > * {
    justify-content: flex-end;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 20px;
    }
  }

  @media screen and (min-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1025px) {
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`


const ListItem = ({ reward, index }) => {
  const isMobile = useMedia('(max-width: 767px)')
  const isTablet = useMedia('(max-width: 1024px)')


  if (reward && reward.id ) {
    return (
      <DashGrid style={{ height: '48px' }} focus={true}>
        <DataText>
          {
            isTablet ?
              formatAddress(reward?.id, 3)
            :
              formatAddress(reward?.id)
          }
          
        </DataText>
        
        {
          !isMobile && 
          <>
            <DataText>
              <Link href={blockUrl + '' + reward.startRewardBlock }>
                {reward.startRewardBlock}
              </Link>
            </DataText>
            <DataText>
              <Link href={blockUrl + '' + reward.endRewardBlock }>
                {reward.endRewardBlock}
              </Link>
            </DataText>
            
            {
              !isTablet && 
              <>
                <DataText>
                  <Link href={blockUrl + '' + reward.startVestingBlock }>
                    {reward.startVestingBlock}
                  </Link>
                </DataText>
                
                <DataText>
                  <Link href={blockUrl + '' + reward.endVestingBlock }>
                    {reward.endVestingBlock}
                  </Link>
                </DataText>
                <DataText>
                  {reward.numOfVestingBlocks}
                </DataText>
              </>
            }
          </>
        }
        
        <DataText area="tvl">
          {reward.lockRewardPercent}%
        </DataText>
        <DataText area="tvl">
          <Link href={tokenUrl + '' + reward.rewardToken}>
            {
              isTablet ?
                formatAddress(reward?.rewardToken, 3)
              :
                formatAddress(reward?.rewardToken)
            }
          </Link>
        </DataText>
      </DashGrid>
    )
  } else {
    return ''
  }
}

const ITEMS_PER_PAGE = 10

function RewardInfo({rewardInfos}) {

  const [page,setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    if (rewardInfos) {
      let extraPages = 1
      if (rewardInfos.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(Object.keys(rewardInfos).length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [rewardInfos])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const rewardInfosFilter =
  rewardInfos &&
  rewardInfos
    .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
    .map((reward, index) => {
      return (
        reward && (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * ITEMS_PER_PAGE + index + 1} reward={reward} />
            <Divider />
          </div>
        )
      )
    })


  const isMobile = useMedia('(max-width: 767px)')
  const isTablet = useMedia('(max-width: 1024px)')

  return (
    <DashboardWrapper>
      <>
        <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
          Rewards Info
        </TYPE.main>{' '}
        <Panel
          style={{
            marginTop: '1.5rem'
          }}
        >
          <DashGrid
            center={true}
            style={{ height: 'fit-content', padding: '0 1.125rem 1rem 1.125rem' }}
          >
            <Flex alignItems="center" justifyContent="flex-start">
              <TYPE.main area="id">Id</TYPE.main>
            </Flex>
            {
              !isMobile && 
              <>
                <Flex alignItems="center" textAlign="right">
                  <TYPE.main area="startReward">Start Reward</TYPE.main>
                </Flex>
                <Flex alignItems="center">
                  <TYPE.main area="endReward">End Reward</TYPE.main>
                </Flex>
                {
                  !isTablet &&
                  <>
                    <Flex alignItems="center"  textAlign="right">
                      <TYPE.main area="startVesting">Start Vesting</TYPE.main>
                    </Flex>
                    <Flex alignItems="center"  textAlign="right">
                      <TYPE.main area="endVesting">End Vesting</TYPE.main>
                    </Flex>
                    <Flex alignItems="center">
                      <TYPE.main area="numberVesting">Number Vesting</TYPE.main>
                    </Flex>
                  </>
                }
              </>
            }
            <Flex alignItems="center" textAlign="right">
              <TYPE.main area="rewardPercent">Reward Percent</TYPE.main>
            </Flex>
            <Flex alignItems="center" textAlign="right">
              <TYPE.main area="rewardToken">Reward Token</TYPE.main>
            </Flex>
          </DashGrid>
          <Divider />
          <List p={0}>{!rewardInfosFilter ? <LocalLoader /> : rewardInfosFilter}</List>
          {
            rewardInfos && rewardInfos.length > 0 &&
            <PageButtons>
              <div
                onClick={e => {
                  setPage(page === 1 ? page : page - 1)
                }}
              >
                <Arrow faded={page === 1 ? true : false}>←</Arrow>
              </div>
              <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
              <div
                onClick={e => {
                  setPage(page === maxPage ? page : page + 1)
                }}
              >
                <Arrow faded={page === maxPage ? true : false}>→</Arrow>
              </div>
            </PageButtons>
          }
        </Panel>
      </>
    </DashboardWrapper>
  )
}

export default RewardInfo
