import React from 'react'
import styled from 'styled-components'
import TokenLogo from '../TokenLogo'

export default function MultiTokenLogo({ tokens, size = 24, margin = false }) {
  return (
    <TokenWrapper sizeraw={size} margin={margin}>
      {tokens &&
        tokens.map(item => (
          <CoveredLogo key={item.id} address={item.address} size={size.toString() + 'px'} sizeraw={size} zindex={1} />
        ))}
    </TokenWrapper>
  )
}

const TokenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`

const CoveredLogo = styled(TokenLogo)`
  background-color: white;
  border-radius: 50%;
  margin-left: -3px;
  z-index: ${({ zindex }) => zindex};
`
