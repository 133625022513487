import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/logo.svg'

const TitleWrapper = styled.div`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  z-index: 10;
`

const LogoIcon = styled(Link)`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    font-weight: 700;
    // color: ${({ theme }) => theme.text1};
    color: #FAFAFA;
  }
`

const WrapLogo = styled.div`
  position: relative;

  img:last-child {
    position: absolute;
    bottom: 6px;
    right: -4px;
    width: 16px;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
  }
`

export default function Title() {
  const history = useHistory()

  const LOGOS = {
    POLYGON: "/images/matic.png",
    BSC: "/images/binance.svg"
  }

  const currentChain = localStorage.getItem('currentChain')

  return (
    <TitleWrapper onClick={() => history.push('/')}>
      <Flex alignItems="center">
        <RowFixed>
          <LogoIcon onClick={() => history.push('/')}>
            <WrapLogo>
              <img width={'36px'} src={Logo} alt="logo" />
              {
                currentChain && (['POLYGON', 'BSC'].includes(currentChain)) ? <img src={LOGOS[currentChain]} alt="alt logo"/> : <img src={LOGOS['POLYGON']} alt={'logo'} />
              }
            </WrapLogo>
            <span>Firebird Info</span>
          </LogoIcon>
        </RowFixed>
      </Flex>
    </TitleWrapper>
  )
}
