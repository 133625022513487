import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from 'rebass'
import styled from 'styled-components'

import { AutoRow, RowBetween } from '../components/Row'
import { AutoColumn } from '../components/Column'
import PairList from '../components/PairList'
import TopTokenList from '../components/TokenList'
import TxnList from '../components/TxnList'
import GlobalChart from '../components/GlobalChart'
import Search from '../components/Search'
import GlobalStats from '../components/GlobalStats'

import { useGlobalData, useGlobalTransactions } from '../contexts/GlobalData'
import { useAllPairData } from '../contexts/PairData'
import { useMedia } from 'react-use'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { formattedNum, formattedPercent } from '../utils'
import { TYPE, ThemedBackground, enumColors } from '../Theme'
import { transparentize } from 'polished'
import { CustomLink } from '../components/Link'

import { PageWrapper, ContentWrapper } from '../components'
import { EXCHANGES_ID } from '../constants'

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

// const selectStyles = {
//   control: styles => ({
//     ...styles,
//     width: '200px',
//     backgroundColor: 'rgba(50, 50, 50, 0.2)',
//     borderColor: '#363f59'
//   }),
//   singleValue: styles => ({
//     ...styles,
//     color: '#ffffff'
//   }),
//   menu: styles => ({
//     ...styles,
//     zIndex: 999
//   })
// }

const exchangeOptions = [
  { value: EXCHANGES_ID.SWAP, label: 'Firebird' },
  { value: EXCHANGES_ID.STABLE_SWAP, label: 'oneSwap' }
]

function GlobalPage() {
  // get data for lists and totals
  const allPairs = useAllPairData()
  const allTokens = useAllTokenData()
  const transactions = useGlobalTransactions()

  const [currentExchange] = useState(exchangeOptions[0])

  const currencyExchangeId = useMemo(() => {
    return currentExchange?.value
  }, [currentExchange])

  const { totalLiquidityUSD, oneDayVolumeUSD, volumeChangeUSD, liquidityChangeUSD } = useGlobalData(currencyExchangeId)
  const stableData = useGlobalData(EXCHANGES_ID.STABLE_SWAP)

  // breakpoints
  const below800 = useMedia('(max-width: 800px)')

  // scrolling refs

  useEffect(() => {
    document.querySelector('body').scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.8, enumColors.primary)} />
      <ContentWrapper>
        <div>
          <AutoColumn gap="24px" style={{ paddingBottom: below800 ? '0' : '24px' }}>
            <TYPE.largeHeader>{below800 ? 'Analytics' : 'Firebird Analytics'}</TYPE.largeHeader>
            <Search />
            {/*<RowBetween>*/}
            <GlobalStats exchangeId={currencyExchangeId} />
            {/*  <Select*/}
            {/*    value={currentExchange}*/}
            {/*    options={exchangeOptions}*/}
            {/*    styles={selectStyles}*/}
            {/*    onChange={setCurrentExchange}*/}
            {/*  />*/}
            {/*</RowBetween>*/}
          </AutoColumn>
          {below800 && ( // mobile card
            <Box my={20}>
              <Panel>
                <Box>
                  <AutoColumn gap="36px">
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Volume (24hrs)</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {formattedNum(oneDayVolumeUSD, true)}
                        </TYPE.main>
                        <TYPE.main fontSize={12}>{formattedPercent(volumeChangeUSD)}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Total Liquidity</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {formattedNum(totalLiquidityUSD, true)}
                        </TYPE.main>
                        <TYPE.main fontSize={12}>{formattedPercent(liquidityChangeUSD)}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </AutoColumn>
                </Box>
              </Panel>
            </Box>
          )}
          {!below800 && (
            <GridRow>
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart exchangeId={currencyExchangeId} display="liquidity" />
              </Panel>
              <Panel style={{ height: '100%' }}>
                <GlobalChart exchangeId={currencyExchangeId} display="volume" />
              </Panel>
            </GridRow>
          )}
          {below800 && (
            <AutoColumn style={{ marginTop: '6px' }} gap="24px">
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart exchangeId={currencyExchangeId} display="liquidity" />
              </Panel>
            </AutoColumn>
          )}

          <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '0.5rem' }}>
            <TYPE.main fontSize={'1.125rem'}>OneSwap</TYPE.main>
          </ListOptions>

          <GlobalStats exchangeId={EXCHANGES_ID.STABLE_SWAP} />

          {below800 ? (
            <>
              <Box mb={20}>
                <Panel>
                  <Box>
                    <AutoColumn gap="36px">
                      <AutoColumn gap="20px">
                        <RowBetween>
                          <TYPE.main>Volume (24hrs)</TYPE.main>
                          <div />
                        </RowBetween>
                        <RowBetween align="flex-end">
                          <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                            {formattedNum(stableData.oneDayVolumeUSD, true)}
                          </TYPE.main>
                          <TYPE.main fontSize={12}>{formattedPercent(stableData.volumeChangeUSD)}</TYPE.main>
                        </RowBetween>
                      </AutoColumn>
                      <AutoColumn gap="20px">
                        <RowBetween>
                          <TYPE.main>Total Liquidity</TYPE.main>
                          <div />
                        </RowBetween>
                        <RowBetween align="flex-end">
                          <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                            {formattedNum(stableData.totalLiquidityUSD, true)}
                          </TYPE.main>
                          <TYPE.main fontSize={12}>{formattedPercent(stableData.liquidityChangeUSD)}</TYPE.main>
                        </RowBetween>
                      </AutoColumn>
                    </AutoColumn>
                  </Box>
                </Panel>
              </Box>

              <AutoColumn style={{ marginTop: '6px' }} gap="24px">
                <Panel style={{ height: '100%', minHeight: '300px' }}>
                  <GlobalChart exchangeId={EXCHANGES_ID.STABLE_SWAP} display="liquidity" />
                </Panel>
              </AutoColumn>
            </>
          ) : (
            <GridRow>
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart exchangeId={EXCHANGES_ID.STABLE_SWAP} display="liquidity" />
              </Panel>
              <Panel style={{ height: '100%' }}>
                <GlobalChart exchangeId={EXCHANGES_ID.STABLE_SWAP} display="volume" />
              </Panel>
            </GridRow>
          )}

          <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.5rem' }}>
            <RowBetween>
              <TYPE.main fontSize={'1.125rem'}>Top Tokens</TYPE.main>
              <CustomLink to={'/tokens'}>See All</CustomLink>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '1.125rem 0 ' }}>
            <TopTokenList tokens={allTokens} />
          </Panel>
          <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.5rem' }}>
            <RowBetween>
              <TYPE.main fontSize={'1rem'}>Top Pools</TYPE.main>
              <CustomLink to={'/pools'}>See All</CustomLink>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '1.125rem 0 ' }}>
            <PairList pairs={allPairs} />
          </Panel>

          <span>
            <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '2rem' }}>
              Transactions
            </TYPE.main>
          </span>
          <Panel style={{ margin: '1rem 0' }}>
            <TxnList transactions={transactions} />
          </Panel>
        </div>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(GlobalPage)
