import React from 'react'
import styled from 'styled-components';

const StyledSelectChain = styled.select`
  background: #1e272d;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-right: 20px;
  font-size: 14px;
  border-color: #363f59;
  cursor: pointer;
`

const CHAINS = [
  {
    id: 'POLYGON',
    title: 'Polygon (Matic)'
  },
  {
    id: 'BSC',
    title: 'Binance Smart Chain'
  }
]

function SelectChain() {

  const currentChain = localStorage.getItem('currentChain') || 'POLYGON'

  const updateChain = (e) => {
    const chain = e.target.value
    localStorage.setItem('currentChain', chain)
    window.location.reload()
  }

  return (
    <StyledSelectChain
      onChange={updateChain}
      defaultValue={currentChain}
    >
      {
        CHAINS.map(chain => (
          <option 
            key={chain.id}
            value={chain.id}
            onClick={() => updateChain(chain.id)}
          >{chain.title}</option>
        ))
      }
    </StyledSelectChain>
  )
}

export default SelectChain
