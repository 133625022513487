import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import LocalLoader from '../LocalLoader'
import utc from 'dayjs/plugin/utc'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

import { CustomLink } from '../Link'
import { Divider } from '../../components'
import { withRouter } from 'react-router-dom'
import { formattedNum, formatAddress } from '../../utils'
import FormattedName from '../FormattedName'
import { TYPE } from '../../Theme'
import MultiTokenLogo from '../MultiTokenLogo'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${props => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.125rem;

  > * {
    justify-content: flex-end;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 20px;
    }
  }

  @media screen and (min-width: 740px) {
    padding: 0 1.125rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1080px) {
    padding: 0 1.125rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ListWrapper = styled.div``

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1};

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const generatePairName = (pairData) => {
  if (!pairData || !pairData.poolId || !pairData.poolId.tokens) return ''
  return pairData.poolId.tokens.map(item => item.symbol).join('-')
}

const getTVL = (pairData) => {
  const isValid = pairData && pairData.totalStakedShare && pairData.poolId && pairData.poolId.totalShares && pairData.poolId.liquidity
  if (!isValid) return 0

  if (pairData.poolId.totalShares <= 0) return 0

  return formattedNum((pairData.totalStakedShare / pairData.poolId.totalShares) * pairData.poolId.liquidity)
}

function PairList({ pairs, color, disbaleLinks, maxItems = 10 }) {
  const below600 = useMedia('(max-width: 600px)')
  const below740 = useMedia('(max-width: 740px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = maxItems


  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [pairs])

  useEffect(() => {
    if (pairs) {
      let extraPages = 1
      if (Object.keys(pairs).length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(Object.keys(pairs).length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [ITEMS_PER_PAGE, pairs])

  const isMobile = useMedia('(max-width: 40em)')

  const ListItem = ({ pair, index }) => {
    const pairData = pair
    if (pairData && pairData.id && pairData.poolId) {
      return (
        <DashGrid style={{ height: '48px' }} disbaleLinks={disbaleLinks} focus={true}>
          <DataText area="id">
            {!below600 && <div style={{ marginRight: '20px', width: '10px' }}>{index}</div>}
            <CustomLink style={{whiteSpace: 'nowrap' }} to={'/staking-pool/' + pairData.id} color={color}>
              {
                isMobile ?
                  formatAddress(pairData.id, 3)
                :
                  formatAddress(pairData.id)
              }
            </CustomLink>
          </DataText>

          <DataText area="pair" fontWeight="500" justifyContent="flex-start">
            <MultiTokenLogo
              size={below600 ? 16 : 20}
              tokens={pairData.poolId.tokens}
              margin={!below740}
            />
            <CustomLink style={{ marginLeft: '10px', whiteSpace: 'nowrap' }} to={'/pool/' + pairData.poolId.id} color={color}>
              <FormattedName
                text={generatePairName(pairData)}
                maxCharacters={below600 ? 25: 25}
                adjustSize={true}
                link={true}
              />
            </CustomLink>
          </DataText>

          <DataText area="tvl">{getTVL(pairData)}</DataText>
        </DashGrid>
      )
    } else {
      return ''
    }
  }

  const pairList =
    pairs &&
    pairs
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((pair, index) => {
        return (
          pair && (
            <div key={index}>
              <ListItem key={index} index={(page - 1) * ITEMS_PER_PAGE + index + 1} pair={pair} />
              <Divider />
            </div>
          )
        )
      })

  return (
    <ListWrapper>
      <DashGrid
        center={true}
        disbaleLinks={disbaleLinks}
        style={{ height: 'fit-content', padding: '0 1.125rem 1rem 1.125rem' }}
      >
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.main area="name">Id</TYPE.main>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.main area="name">Pair</TYPE.main>
        </Flex>
        <Flex alignItems="center">
          <TYPE.main area="name">TVL</TYPE.main>
        </Flex>
      </DashGrid>
      <Divider />
      <List p={0}>{!pairList ? <LocalLoader /> : pairList}</List>
      <PageButtons>
        <div
          onClick={e => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div
          onClick={e => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons>
    </ListWrapper>
  )
}

export default withRouter(PairList)
