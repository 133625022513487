const NODE_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'production';

export const POLYGON = 137
export const BSC = 56

const dev = {
  subgraph: {
    POLYGON: 'https://api.thegraph.com/subgraphs/name/firebirdgraph/polygon-exchange',
    BSC: 'https://api.thegraph.com/subgraphs/name/firebirdgraph/smartchain-exchange',
  },
  block: {
    POLYGON: 'https://api.thegraph.com/subgraphs/name/sameepsi/maticblocks',
    BSC: 'https://api.bscgraph.org/subgraphs/name/bsc-blocks',
  },
}

const prod = {
  subgraph: {
    POLYGON: 'https://api.thegraph.com/subgraphs/name/firebirdgraph/polygon-exchange',
    BSC: 'https://api.thegraph.com/subgraphs/name/firebirdgraph/smartchain-exchange',
  },
  block: {
    POLYGON: 'https://api.thegraph.com/subgraphs/name/sameepsi/maticblocks',
    BSC: 'https://api.bscgraph.org/subgraphs/name/bsc-blocks',
  },
}

const config = NODE_ENV === 'development' ? dev : prod

export default config
