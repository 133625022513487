import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { formatAddress, formattedNum} from '../../utils'

import LocalLoader from '../LocalLoader'
import { Box, Flex } from 'rebass'
import Link from '../Link'
import { Divider, EmptyCard } from '..'
import { TYPE } from '../../Theme'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: #2f80ed;
  opacity: ${props => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'txn balance balanceusd';

  > * {
    justify-content: flex-end;
    width: 100%;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  @media screen and (min-width: 500px) {
    > * {
      &:first-child {
        width: 180px;ListHolder
      }
    }
  }
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.text1};

  & > * {
    font-size: 1em;
  }

  @media screen and (max-width: 40em) {
    font-size: 0.85rem;
  }
`


const ITEMS_PER_PAGE = 10

const getPriceUSD = (holder) => {
  if (!holder || !holder.poolId) return 0

  const price = (holder.poolId.liquidity / holder.poolId.totalShares)
  return formattedNum(holder.balance * price, 2)
}

function TxnList({ holders, color }) {
  // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [holders])

  useEffect(() => {
    if (holders) {
      let extraPages = 1
      if (holders.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(holders.length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [holders])

  const filteredList =
      holders &&
      holders
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)

  const ListItem = ({ item }) => {
    
    const addressUrl = 'https://explorer-mainnet.maticvigil.com/address/'

    return (
      <DashGrid style={{ height: '48px' }}>
        <DataText area="time">
          <Link href={addressUrl + '' + item.userAddress.id }>
            {formatAddress(item.userAddress.id)}
          </Link>
        </DataText>
        <DataText area="time">
          {formattedNum(item.balance)}
        </DataText>
        <DataText area="time">
           {getPriceUSD(item)}
        </DataText>
      </DashGrid>
    )
  }

  return (
    <>
      <DashGrid center={true} style={{ height: 'fit-content', padding: '0 0 1rem 0' }}>

        <>
          <Flex alignItems="center">
            <TYPE.body area="account">Account</TYPE.body>
          </Flex>
          <Flex alignItems="center">
            <TYPE.body area="account">Balance</TYPE.body>
          </Flex>
          <Flex alignItems="center">
            <TYPE.body area="account">Balance USD</TYPE.body>
          </Flex>
        </>

      </DashGrid>
      <Divider />
      <List p={0}>
        {!filteredList ? (
          <LocalLoader />
        ) : filteredList.length === 0 ? (
          <EmptyCard>No recent transactions found.</EmptyCard>
        ) : (
          filteredList.map((item, index) => {
            return (
              <div key={index}>
                <ListItem key={index} index={index + 1} item={item} />
                <Divider />
              </div>
            )
          })
        )}
      </List>
      <PageButtons>
        <div
          onClick={e => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div
          onClick={e => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons>
    </>
  )
}

export default TxnList
