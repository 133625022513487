import React from 'react'
import { TYPE, ThemedBackground } from '../../Theme'
import { PageWrapper, ContentWrapperLarge } from '../../components/index'
import { RowBetween } from '../../components/Row'
import { useStakingPairData, useStakingHolder } from '../../contexts/StakingPairData'
import { BasicLink } from '../../components/Link'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { useColor } from '../../hooks'
import {  formatAddress } from '../../utils'
import RewardInfo from './components/RewardInfo.jsx'
import Holder from './components/Holder'

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function StakingPairPage({pairAddress}) {
  const backgroundColor = useColor(pairAddress)
  const  pair = useStakingPairData(pairAddress)
  const holders = useStakingHolder(pairAddress)

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />
      <span />
      <ContentWrapperLarge>
        <RowBetween>
          <TYPE.body>
            <BasicLink to="/staking-pools">{'Staking Pools '}</BasicLink>→  {formatAddress(pairAddress)}
          </TYPE.body>
        </RowBetween>
        <WarningGrouping
        >
          <Holder holders={holders} />
          <RewardInfo rewardInfos={pair.rewardInfos} />
        </WarningGrouping>
      </ContentWrapperLarge>
    </PageWrapper>
  )
}

export default StakingPairPage
