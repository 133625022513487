import React, { createContext, useReducer, useContext, useEffect, useState } from 'react'
import { valueLiquidClient } from '../apollo/client'
import { 
  // GET_STAKING_POOLS, 
  GET_STAKING_POOL, GET_STAKING_POOL_HOLDER } from '../apollo/queries'

const StakingPairContext = createContext()

const UPDATE = 'UPDATE'

const reducer = (state, {type, payload}) => {
  switch(type) {
    case UPDATE:
      return payload
    default:
      throw Error('Unexpected action type')
  }
}

const Provider = ({children}) => {

  const [state, dispatch] = useReducer(reducer, {})

  const update = (data) => {
    dispatch({
      type: UPDATE,
      payload: data
    })
  }

  return (
    <StakingPairContext.Provider
      value={{
        state,
        update
      }}
    >
      {children}
    </StakingPairContext.Provider>
  )
}

export default Provider

export const useStakingPairContext = () => {
  return useContext(StakingPairContext)
}

export const Updater = () => {
  // FIXME: implement staking pools

  // const {update} = useStakingPairContext()


  // const handleUpdateData = async() => {
  //   let result = await valueLiquidClient.query({
  //     query: GET_STAKING_POOLS,
  //     fetchPolicy: 'cache-first'
  //   })
  //   const data = result?.data?.stakingPools
  //   update(data)
  // }

  // useEffect(() => {
  //   handleUpdateData()
  //  // eslint-disable-next-line
  // }, [])

  return null
}

export const useAllStakingPairData = () => {
  const {state} = useStakingPairContext()
  return state || []
}

export const useStakingPairData = (pairAddress) => {
  const [data, setData] = useState({})

  const getData = async () => {
    const result = await valueLiquidClient.query({
      query: GET_STAKING_POOL,
      variables: {
        pairAddress: pairAddress.toString()
      },
      fetchPolicy: 'cache-first'
    })
    result?.data?.stakingPools && setData(result.data.stakingPools[0] || {})
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return data
}

export const useStakingHolder = (stakingPoolId) => {
  const [data, setData] = useState([])

  const getData = async () => {
    const result = await valueLiquidClient.query({
      query: GET_STAKING_POOL_HOLDER,
      variables: {
        stakingPoolId: stakingPoolId.toString()
      },
      fetchPolicy: 'cache-first'
    })
    result?.data?.stakePoolShares && setData(result.data.stakePoolShares || [])
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return data
}
